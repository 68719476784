import React from 'react';
import classNames from 'classnames';

const Banner = ({ showContent }) => {
  const clientInfo = window.__E_TT_Client;
  return (
    <div className={classNames({
      "banner": 1,
      "hideBanner": showContent
    })}>
      <span className="overlay-banner-logo">
        <img src={clientInfo.logo} alt={clientInfo.title} />
      </span>
      <span className="creator_credits">
        <a href={`https://www.ecollat.com/?utm_source=client-loader&utm_medium=web&utm_campaign=${clientInfo.companySlug}`} rel="noopener noreferrer" target="_blank">
          <span>Powered by </span>
          <span><img src="https://www.ecollat.com/images/logo-mobile.png" alt="ecollat" /></span>
        </a>
      </span>
    </div>
  );
}

export default Banner;
import React from "react";
import * as ServiceWorker from "./Action/serviceWorker";
import ReactDOM from 'react-dom';
// Importing Components
import App from "./App";

// Importing App CSS
import "./Assets/css/reboot.css";
import "./Assets/css/key-frames.css";
import "./Assets/css/style.css";
import "./Assets/css/mobile.css";
import {
  setupGoogleAnalytics,
  // setupGoogleAnalytics,
  setupGoogleTagManager,
} from "./Functions/analytics";
import { removeAllCaches } from "./Action";
// import { createRoot } from "react-dom/client";
const init = () => {
  setupGoogleAnalytics();  // hariom uncomment for old
  if (window.__E_TT_Client?.gtm) {
    //new by hariom remove for old
    setupGoogleTagManager();
  }
  //console error
  if (process.env.NODE_ENV === "Production") {
    console.log = () => { };
    console.error = () => { };
    console.warn = () => { };
    console.debug = () => { };
  }
  //console error
  // React App Entry Div


  // Get the 'isdeleted' value from localStorage
  const storedIsDeleted = localStorage.getItem('isdeleted');

  // Convert the window.__E_TT_Client.isDelete value to a string
  const currentIsDeleted = window.__E_TT_Client?.isDelete?.toString();

  // Compare the stored value with the current value
  if (storedIsDeleted !== currentIsDeleted) {
    // If they are different, remove all caches
    removeAllCaches();
  }


  // const root = createRoot(document.querySelector("#ecollat-entry"));
  // root.render(<App />);
  const appRoot = document.querySelector("#ecollat-entry");
  ReactDOM.render(< App />, appRoot);
  // process.env.NODE_ENV === 'Production'
  // if (process.env.NODE_ENV === "Production") {
  ServiceWorker.handleServiceWorker();
  // }
};

// if (process.env.NODE_ENV !== 'production') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//     trackAllPureComponents: true
//   })
// }

init();

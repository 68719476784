import React, { useState, useEffect, useContext } from "react";
import { Layout, Header } from "../../App";
import PdfList from "./Pdf-list";
import SearchResult from "../Viewer/searchResult";
import {
  trackPageGoogleAnalytics,
  trackPageGoogleTagManager,
} from "../../Functions/analytics";
import { svgIcon } from "../../Functions";
import { searchInAllPdfs } from "./../../Action/index";
import _ from "lodash";
import { GlobalSearchContext } from "../Viewer/context";
import BottomNavigation from "../Viewer/bottom-navigation";
import Popup from "./../../Containers/Popup";
import Footer from "../../App/Footer";
import { HomePageView } from "../../Functions/analytic";

const List = (props) => {
  const [state, setState] = useState({
    error: false,
    loading: true,
    showSidebar: false,
    showSearch: false,
    searchLoading: false,
    showResult: false,
    showPopup: {
      isActive: false,
      data: null,
      type: null,
    },
    enablePdfGenerate: false,
  });

  const context = useContext(GlobalSearchContext);

  const toggleSidebar = (value) => {
    setState((prevState) => ({
      ...prevState,
      showSidebar: typeof value === "boolean" ? value : !prevState.showSidebar,
      showSearch: false,
      searchResult: null,
    }));

    setTimeout(() => updateSliderCalc(), 250);
  };

  const toggleSearch = (value, clearSearch) => {
    if (clearSearch) {
      setState({
        showSearch: false,
        searchResult: null,
        showResult: false,
      });

      setTimeout(() => updateSliderCalc(), 250);
    } else {
      setState((prevState) => ({
        ...prevState,
        showSearch: typeof value === "boolean" ? value : !prevState.showSearch,
        showSidebar: false,
      }));
    }
  };

  const toggleResult = (value) => {
    if (window.innerWidth < window.innerHeight) {
      setState((prevState) => ({
        ...prevState,
        showResult: typeof value === "boolean" ? value : !prevState.showResult,
      }));
    }
  };

  const search = async (term) => {
    const companySlug = window.__E_TT_Client.companySlug;
    let searchResult = [];
    let data;
    let allPdfs = props.pdfs;
    setState({ ...state, searchLoading: true });
    let res = await searchInAllPdfs(companySlug, term);

    for (let i = 0; i < allPdfs.length; i++) {
      let resData = res[allPdfs[i].pdfID];
      let _searchResult;

      if (resData != undefined && resData[0] && resData[0].coordinates) {
        let results = [];
        resData.map((p) => {
          let r = eval(p.coordinates);
          results = [...results, ...r];

          return true;
        });

        _searchResult = _.groupBy(results, (p) => p.page_no);
      } else _searchResult = resData;

      data = {
        pdf_id: allPdfs[i].pdfID,
        data: _searchResult,
        pdf: allPdfs[i],
      };

      data.data != undefined && searchResult.push(data);
    }

    context.handleSearchResult(searchResult);
    setState({ ...state, searchLoading: false, showResult: true });

    setTimeout(() => updateSliderCalc(), 250);
  };

  const updateSliderCalc = () => {
    const { view } = {};
    if (view && view.calcSlider) view.calcSlider();
  };

  const togglePopup = (config, closePopup = false) => {
    if (closePopup) {
      let defaultConfig = {
        isActive: false,
        data: null,
        type: null,
      };
      return setState({ ...state, showPopup: defaultConfig });
    }
    setState({ ...state, showPopup: config });
  };

  const { pdfs = [], cookiePolicyData } = props;
  const howItWorksVideo = pdfs[0] && pdfs[0].howworksPath;
  const howItWorksConfig = {
    isActive: true,
    data: howItWorksVideo,
    type: "howItWorks",
  };

  const config = {
    showCart: pdfs[0] && pdfs[0].showCart,
    binderSearch: pdfs[0] && pdfs[0].binderSearch,
  };


  useEffect(() => {
    // VisitHome()
    HomePageView({
      ishome: true,
      scroll: true,
    })
  }, [])


  return (
    <>
      <Layout togglePopup={togglePopup} pdf={pdfs[0]} scrollable>
        <Header
          config={config}
          hasSearch={config.binderSearch}
          menuData={state.menuData}
          toggleSidebar={toggleSidebar}
          toggleResult={toggleResult}
          toggleSearch={toggleSearch}
          search={search}
          isSidebarOpen={state.showSidebar}
          isSearchOpen={state.showSearch}
          searchLoading={state.searchLoading}
          isHomePage={true}
        />

        <PdfList data={pdfs} />
        <BottomNavigation
          pdf={pdfs[0]}
          pdfID={0}
          isHome={true}
          hideCustomPdf={true}
          howItWorksVideo={howItWorksVideo && true}
          atHome={true}
          hideDownloadHome={true}
          togglePopup={togglePopup}
          socialMediaLinks={props.socialMediaLinks}
        />

        {howItWorksVideo ? (
          <span
            className="goto-howitworks"
            onClick={() => togglePopup(howItWorksConfig)}
          >
            <span className="howitworks-icon">{svgIcon("ico-play")}</span>
            <span>How it works</span>
          </span>
        ) : (
          []
        )}

        <SearchResult
          pdf={props.pdfs}
          result={context.searchResult}
          closeSearch={toggleResult}
          isSearchOpen={state.showResult}
        />
        <Popup config={state.showPopup} close={() => togglePopup({}, true)} />
        {cookiePolicyData ? <Footer /> : []}
      </Layout>
    </>
  );
};

export default List;

/** =======================================   Testing Urls ================================= */

// export const HOST = "https://www.app.ecollat.com";
// // API HOST
// export const HOST = "https://app.turnintern.com";
// export const HOST = "http://127.0.0.1:8080/";
// export const HOST = "https://2347ed79dec2.ngrok.io"
// export const APP_DOMAIN = "http://localhost:3000"; // APP DOMAIN TO PUBLISH CLIENT PDF ON OUR DOMAIN

export const ECOLLAT_CACHE = "ecollat-images-cache";
export const PDF_DB = "pdf_data";



















/** ==================================================== Configrations =============================================================== */

export const hostorigin= process.env.REACT_APP_HOSTORIGIN===""? window.location.origin : process.env.REACT_APP_HOSTORIGIN
export const APP_DOMAIN = process.env.REACT_APP_APP_DOMAIN===""? window.location.origin : process.env.REACT_APP_APP_DOMAIN; // APP DOMAIN TO PUBLISH CLIENT PDF ON OUR DOMAIN
export const HOST = process.env.REACT_APP_API_HOST;  // new chandan sir
export const ASSETS_URI = process.env.REACT_APP_ASSETS_URI //for dev by chanden sir Images


/** ========================================================== END =================================================================== */


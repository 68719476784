import React from 'react';
import classnames from 'classnames';

const Overlay = (props) => {
  const { isLoading, clientInfo, showLogo, showCredits, customClass, closeCart } = props;

  const getLogo = (clientInfo) => {
    let logoSrc = clientInfo.logo;

    return (
      <div className="logowrap">
        <img src={logoSrc} alt="Logo" />
      </div>
    );
  };

  return (
    <div
      onClick={() => closeCart()}
      className={classnames({
        'app-overlay': true,
        'in': isLoading,
        'out': !isLoading,
      }, customClass)}
    >
      {showLogo && getLogo(clientInfo)}

      {showCredits && (
        <span className="creator_credits">
          <a href="https://www.ecollat.com/" rel="noopener noreferrer" target="_blank">
            <span>Powered by </span>
            <span><img src="https://www.ecollat.com/images/logo-mobile.png" alt="ecollat" /></span>
          </a>
        </span>
      )}
    </div>
  );
};

export default Overlay;
